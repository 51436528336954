<script setup>
import {computed, onMounted, ref} from "vue";
import {request} from "@/utils/axiosSetting.js";
import {useI18n} from "vue-i18n";
import {getLocalItem, setLocalItem} from "@/utils/auth.js";

const {locale} = useI18n();

const confirmCookieItem = !getLocalItem("confirmCookie");

const activeNames = ref([]);
const rightSwitch = ref(true);
const dialogVisible = ref(false);
const loading = ref(false);
const showCookie = ref(confirmCookieItem);
const infoSource = ref(null);
const info = computed(() => {
    if (locale.value === "cn") {
        // 中文
        return {
            homeDes: infoSource.value?.homeDesCN,
        };
    } else if (locale.value === "en") {
        // 英文
        return {
            homeDes: infoSource.value?.homeDesEN,
        };
    }
});

const confirmClick = () => {
    showCookie.value = false;
    dialogVisible.value = false;
    setLocalItem("confirmCookie", true);
};

const settingClick = () => {
    dialogVisible.value = true;
};

const handleClose = (done) => {
    showCookie.value = false;
};

const getData = async () => {
    const res = await request("/api/RestController.php", {
        method: "GET",
        params: {
            view: "home",
            type: "cookie",
        },
    });

    infoSource.value = res.data;
};

onMounted(async () => {
    try {
        loading.value = true;
        await getData();
    } finally {
        loading.value = false;
    }
});
</script>

<template>
    <!-- cookie 权限抽屉 -->
    <el-drawer
        class="cookie-drawer-class"
        :model-value="showCookie"
        direction="btt"
        :z-index="999"
        :with-header="false"
        :before-close="handleClose"
    >
        <div class="info">
            <p v-loading="loading">{{ $t("cookie.bottom") }}</p>
            <div class="show-cookie-btn">
                <el-button @click="settingClick">{{ $t("cookie.settings") }}</el-button>
                <el-button @click="confirmClick">{{ $t("cookie.accept") }}</el-button>
            </div>
        </div>
    </el-drawer>
    <!-- cookie 设置弹框 -->
    <el-dialog
        class="cookie-dialog-class"
        :model-value="dialogVisible"
        :z-index="9999"
        :title="$t('cookie.privacy.overview')"
    >
        <div class="pb-2">
            {{ $t("cookie.privacy.overview.content") }}
        </div>
        <div>
            <el-collapse v-model="activeNames">
                <el-collapse-item :name="1">
                    <template #title>
                        <div class="collapse-title">
                            <span>{{ $t("cookie.privacy.always.necessary") }}</span>
                            <div class="collapse-right">{{ $t("cookie.privacy.always.necessary.enabled") }}</div>
                        </div>
                    </template>
                    <div class="collapse-content">{{ $t("cookie.privacy.always.enabled.content") }}</div>
                </el-collapse-item>
                <el-collapse-item :name="2">
                    <template #title>
                        <div class="collapse-title">
                            <span>{{ $t("cookie.privacy.always.non.necessary") }}</span>
                            <div class="collapse-right">
                                <el-switch
                                    @click.stop
                                    v-model="rightSwitch"
                                    inline-prompt
                                    :active-text="$t('cookie.privacy.always.non.necessary.enable')"
                                    :inactive-text="$t('cookie.privacy.always.non.necessary.disabled')"
                                    style="--el-switch-on-color: #67C23A; --el-switch-off-color: #ff4949"
                                />
                            </div>
                        </div>
                    </template>
                    <div class="collapse-content">{{ $t("cookie.privacy.always.non.necessary.content") }}</div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="success" @click="confirmClick">{{ $t("saveAndAccept") }}</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<style lang="less">
.cookie-drawer-class {
    height: auto !important;
}

.cookie-dialog-class {
    --el-dialog-width: min(520px, 90%) !important;

    .el-collapse-item {
        border-radius: 8px;
        margin-top: 8px;
        overflow: hidden;
    }

    .el-collapse-item__header {
        padding: 10px;
        background-color: #F2F2F2;
    }

    .el-collapse-item__content {
        padding-bottom: 6px;
        color: #555;
    }
}
</style>

<style scoped lang="less">
.show-cookie {
    position: fixed;
    bottom: 0;
    right: 20px;
}

.show-cookie-btn {
    margin-top: 10px;
    text-align: right;
}

.collapse-title {
    width: 100%;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapse-content {
    padding: 8px 20px;
}
</style>