import {ref, computed} from "vue";
import {defineStore} from "pinia";
import {request} from "@/utils/axiosSetting.js";
import {useI18n} from "vue-i18n";


export const useSearchStore = defineStore("searcher", () => {
    const {locale} = useI18n();

    const searchValue = ref("");
    const loading = ref(false);
    const currentPage = ref(1);
    const pageSize = ref(20);
    const total = ref(0);
    const totalPage = ref(0);

    const tableDataSource = ref([]);
    const tableData = computed(() => {
        if (locale.value === "cn") {
            // 中文
            return tableDataSource.value.map(item => {
                return {
                    title: item.titleCN,
                    reportFile: item.reportFileCN,
                };
            });
        } else if (locale.value === "en") {
            // 英文
            return tableDataSource.value.map(item => {
                return {
                    title: item.titleEN,
                    reportFile: item.reportFileEN,
                };
            });
        }
    });

    const handleSizeChange = async (val) => {
        pageSize.value = val;
        try {
            loading.value = true;
            await getData();
        } finally {
            loading.value = false;
        }
    };
    const handleCurrentChange = async (val) => {
        currentPage.value = val;
        try {
            loading.value = true;
            await getData();
        } finally {
            loading.value = false;
        }
    };

    const getData = async () => {
        const res = await request("/api/RestController.php", {
            method: "GET",
            params: {
                view: "search",
                searchKey: searchValue.value,
                page: currentPage.value,
                pageSize: pageSize.value,
            },
        });

        tableDataSource.value = res.data || [];
        total.value = Number(res.total) || 0;
        totalPage.value = Number(res.totalpage) || 0;
    };

    const search = async (value) => {
        try {
            loading.value = true;
            searchValue.value = value;
            currentPage.value = 1;
            await getData();
        } finally {
            loading.value = false;
        }
    };

    return {
        loading,
        currentPage,
        pageSize,
        total,
        totalPage,
        tableDataSource,
        tableData,
        handleSizeChange,
        handleCurrentChange,
        getData,
        search,
    };
});
