import {createI18n} from "vue-i18n";
import {request} from "@/utils/axiosSetting.js";
import {getItem, setItem} from "@/utils/auth.js";

const defaultMessages = {
    cn: {
        inputPlaceholder: "请输入...",
        currentLanguage: "当前语言",
        defaultAnnualReport: "年度报告",
        defaultInterimReport: "中期报告",
        noData: "没有数据",
        searchResults: "搜索结果",
        totalPage: `共 {num} 页`,
        pageSize: `{num}条/页`,
        address: "地址",
        email: "电邮",
        tel: "电话",
        cookieConfirm: "接受并关闭",
        saveAndAccept: "接受并保存",
        "home.company.name": '申洲国际',
    },
    en: {
        inputPlaceholder: "Please Input...",
        currentLanguage: "Current language",
        defaultAnnualReport: "Annual",
        defaultInterimReport: "Interim",
        noData: "No Data",
        searchResults: "Search Results",
        totalPage: `Total {num} pages`,
        pageSize: `{num}/page`,
        address: "Address",
        email: "Email",
        tel: "Tel",
        cookieConfirm: "ACCETTA E CHIUDI",
        saveAndAccept: "SAVE & ACCEPT",
        "home.company.name": 'Shenzhou International',
    },
};

export const i18n = createI18n({
    locale: getItem("localeLanguage") || "cn", // 设置默认语言
    fallbackLocale: getItem("localeLanguage" || "cn"), // 设置回退语言
});

export const handleLocalMessage = async () => {
    const res = await request("/api/RestController.php", {
        method: "GET",
        params: {
            view: "i18n",
        },
    });

    let info = res.data;

    let enMessage = {};
    let cnMessage = {};
    Object.keys(info).forEach((key) => {
        cnMessage[key] = info[key].cn;
        enMessage[key] = info[key].en;
    });
    cnMessage = {...defaultMessages.cn, ...cnMessage};
    enMessage = {...defaultMessages.en, ...enMessage};

    i18n.global.setLocaleMessage("en", enMessage);
    i18n.global.setLocaleMessage("cn", cnMessage);
};