import {createRouter, createWebHistory} from "vue-router";

import LayoutBase from "@/layouts/LayoutBase.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            redirect: "/about",
            component: LayoutBase,
            children: [
                {
                    path: "search",
                    name: "search",
                    component: () => import("../views/search/index.vue"),
                    meta: {
                        titleKey: "searchResults",
                    }
                },
                {
                    path: "about",
                    name: "about",
                    component: () => import("../views/about/index.vue"),
                    meta: {
                        titleKey: "home",
                    }
                },
                {
                    path: "corporate-governance",
                    name: "corporateGovernance",
                    redirect: "/corporate-governance/guidelines-policies",
                    meta: {
                        titleKey: "corporate.governance",
                    },
                    children: [
                        {
                            path: "guidelines-policies",
                            name: "guidelinesPolicies",
                            component: () => import("@/views/corporate_governance/guidelines_policies/index.vue"),
                            meta: {
                                titleKey: "policy.strategy",
                            }
                        },
                        {
                            path: "our-leadership",
                            name: "ourLeadership",
                            component: () => import("@/views/corporate_governance/our_leadership/index.vue"),
                            meta: {
                                titleKey: "leading.team",
                            }
                        },
                    ],
                },
                {
                    path: "investor-relations",
                    name: "investorRelations",
                    redirect: "/investor-relations/announcements",
                    meta: {
                        titleKey: "investor.relations",
                    },
                    children: [
                        {
                            path: "announcements",
                            name: "announcements",
                            component: () => import("@/views/investor_relations/announcements/index.vue"),
                            meta: {
                                titleKey: "announcement",
                            }
                        },
                        {
                            path: "reports",
                            name: "reports",
                            component: () => import("@/views/investor_relations/reports/index.vue"),
                            meta: {
                                titleKey: "all.report",
                            }
                        },
                        {
                            path: "shareholder-info",
                            name: "shareholderInfo",
                            component: () => import("@/views/investor_relations/shareholder_info/index.vue"),
                            meta: {
                                titleKey: "shareholder.info",
                            }
                        },
                    ],
                },
                // {
                //     path: "contact-us",
                //     name: "contactUs",
                //     component: () => import("@/views/contact_us/index.vue"),
                //     meta: {
                //         titleKey: "contact.us",
                //     }
                // },
            ],
        },
    ],
});

export default router;
