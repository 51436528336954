import axios from "axios";
import {ElMessage} from "element-plus";
import {getToken} from "./auth.js";

// create an axios instance
const service = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 20000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        let token = getToken();

        if (token && config.headers) {
            config.headers.token = token;
        }

        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        // TODO: 这里要定义 res 的数据结构, 和后端返回的大体结构相同
        const res = response.data;

        // // 如果请求异常
        if (res.code !== 200) {
            if (res.code === 403) { // token 过期

            } else {
                ElMessage({
                    message: res.msg,
                    type: "error",
                    plain: true,
                });
            }

            // return res; // Promise.reject(new Error(res.msg || 'Error'))
            return Promise.reject(res);
        }

        return res;
    },
    (error) => {
        const codeMessage = {
            200: "服务器成功返回请求的数据。",
            201: "新建或修改数据成功。",
            202: "一个请求已经进入后台排队（异步任务）。",
            204: "删除数据成功。",
            400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
            401: "用户没有权限（令牌、用户名、密码错误）。",
            403: "用户得到授权，但是访问是被禁止的。",
            404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
            406: "请求的格式不可得。",
            410: "请求的资源被永久删除，且不会再得到的。",
            422: "当创建一个对象时，发生一个验证错误。",
            500: "服务器发生错误，请检查服务器。",
            502: "网关错误。",
            503: "服务不可用，服务器暂时过载或维护。",
            504: "网关超时。",
        };

        const {response} = error;
        // console.log('error', error);
        // console.log('response', response);
        // console.log('JSON.str', JSON.stringify(error));
        // console.log('error.code', error.code);
        // console.log('request', error.request);

        // 处理超时
        if (!response) {
            // 没有正确发送请求，可能是超时或请求代码 BUG
            ElMessage({
                message: "网络超时, 请重试",
                type: "error",
                plain: true,
            });
            return error;
        }

        // 处理错误状态
        const {
            status,
            statusText,
            data: { // data 里面的数据字段名需要和后台协商一致
                msg = "服务器发生未知错误",
            },
        } = response;

        ElMessage({
            message: `${status}:${msg || codeMessage[status] || statusText}`,
            type: "error",
            plain: true,
        });

        return Promise.reject(error);
    },
);

const request = async (_url, _options) => {
    const url = _url; // baseURL 在 axios 的 baseURL 内设置, 这样如果 _url 是一个绝对 url 则不会被 axios 添加 baseURL
    _options.method || (_options.method = "POST");
    const options = {
        url,
        ..._options,
    };

    return service(options); // 返回的值需要判断 code 是否为 0 ，是则请求正常，否则是异常
};

export {
    request,
};

export default service;
