<script setup>
import {ref, onMounted, nextTick} from "vue";
import {useRouter, useRoute} from "vue-router";
import logo from "@/assets/logo.png";
import Language from "@/layouts/components/Language.vue";
import {request} from "@/utils/axiosSetting.js";
import MenuItem from "@/layouts/components/MenuItem.vue";
import {useSearchStore} from "@/stores/search.js";
import {useI18n} from "vue-i18n";
import ShowCookie from "@/components/ShowCookie.vue";
import {useMetaInfoStore} from "@/stores/meta_info.js";

const searcher = useSearchStore();
const metaInfo = useMetaInfoStore();

const {t, locale} = useI18n();
const route = useRoute();
const router = useRouter();

const drawer = ref(false);
const loading = ref(true);
const searchValue = ref("");
const defaultActive = ref(route.path);
const menuList = ref([]);
const inputFocus = ref(false);

const searchChange = async () => {
    if (!searchValue.value) {
        return;
    }

    drawer.value = false;
    await searcher.search(searchValue.value);
    router.push("/search");
};

const handleSelect = (key, keyPath) => {
    console.log(key, keyPath);
    drawer.value = false;
};
const goAbout = () => {
    location.href = "/";
    document.title = t(route.meta?.titleKey);
};

const handleClose = (done) => {
    done();
};

const getMenu = async () => {
    const res = await request("/api/RestController.php", {
        method: "GET",
        params: {
            view: "menu",
        },
    });

    let info = res.data || [];
    info = info.filter((item) => {
        return Number(item.cid) !== 0;
    });

    menuList.value = [
        {
            "cid": "108",
            "fid": "0",
            "ckind": "2",
            "cvalue": "about.shenzhou",
            "ifshownav": "1",
            "name": "关于申洲",
            "target": "",
            "url": "/about",
            "onstyle": "",
            "sub": [],
        },
        {
            "cid": "171",
            "fid": "0",
            "ckind": "2",
            "cvalue": "corporate.governance",
            "ifshownav": "1",
            "name": "企业治理",
            "target": "",
            "url": "/corporate-governance",
            "onstyle": "",
            "sub": [
                {
                    "cid": "173",
                    "fid": "171",
                    "ckind": "2",
                    "cvalue": "policy.strategy",
                    "ifshownav": "1",
                    "name": "方针及策略",
                    "target": "",
                    "url": "/corporate-governance/guidelines-policies",
                    "onstyle": "",
                },
                {
                    "cid": "174",
                    "fid": "171",
                    "ckind": "2",
                    "cvalue": "leading.team",
                    "ifshownav": "1",
                    "name": "领导团队",
                    "target": "",
                    "url": "/corporate-governance/our-leadership",
                    "onstyle": "",
                },
            ],
        },
        {
            "cid": "110",
            "fid": "0",
            "ckind": "2",
            "cvalue": "investor.relations",
            "ifshownav": "1",
            "name": "投资者关系",
            "target": "",
            "url": "/investor-relations",
            "onstyle": "",
            "sub": [
                {
                    "cid": "130",
                    "fid": "110",
                    "ckind": "2",
                    "cvalue": "announcement",
                    "ifshownav": "1",
                    "name": "公告",
                    "target": "",
                    "url": "/investor-relations/announcements",
                    "onstyle": "",
                },
                {
                    "cid": "176",
                    "fid": "110",
                    "ckind": "2",
                    "cvalue": "all.report",
                    "ifshownav": "1",
                    "name": "报告",
                    "target": "",
                    "url": "/investor-relations/reports",
                    "onstyle": "",
                },
                {
                    "cid": "175",
                    "fid": "110",
                    "ckind": "1",
                    "cvalue": "shareholder.info",
                    "ifshownav": "1",
                    "name": "股东信息",
                    "target": "",
                    "url": "/investor-relations/shareholder-info",
                    "onstyle": "",
                },
            ],
        },
        // {
        //     "cid": "114",
        //     "fid": "0",
        //     "ckind": "2",
        //     "cvalue": "contact.us",
        //     "ifshownav": "1",
        //     "name": "联系我们",
        //     "target": "",
        //     "url": "/contact-us",
        //     "onstyle": "",
        //     "sub": []
        // }
    ];
};

onMounted(async () => {
    try {
        loading.value = true;
        await getMenu();
    } finally {
        loading.value = false;
    }
});
</script>

<template>
    <div class="menu flex items-center justify-center w-screen" v-loading="loading">
        <div class="flex-1 flex items-center justify-between px-2 py-2 sm:py-0 sm:px-6 lg:px-8 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl">
            <div class="logo-info flex flex-shrink-0 items-center mr-6" @click="goAbout">
                <!--<img @click="goAbout" class="h-8 w-auto cursor-pointer" :src="logo" alt="Your Company" />-->
                <!--                <h1 class="logo-title page-title" @click="goAbout">{{ $t("home.company.name") }}</h1>-->
                <img :src="logo" alt="logo" class="logo" />
                <span v-show="metaInfo.i18nIsLoaded" :style="{
                    'letter-spacing': locale === 'en' ? 'normal' : '0.2em'
                }">{{ $t("home.company.name") }}</span>
            </div>
            <div class="el-menu-content hidden xl:block" :style="{
                '--max-margin-y': locale === 'en' ? '20px' : '20px',
                'letter-spacing': locale === 'en' ? 'normal' : '0.3em',
                marginLeft: 'auto',
            }">
                <el-menu
                    v-model="defaultActive"
                    mode="horizontal"
                    router
                    :default-active="defaultActive"
                    @select="handleSelect"
                >
                    <MenuItem :menuList="menuList"></MenuItem>
                </el-menu>
            </div>
            <div class="flex-shrink-0 xl:flex-initial flex items-center justify-end">
                <!-- 移动端菜单栏 -->
                <div class="flex items-center justify-center w-8 h-8 cursor-pointer xl:hidden" @click="drawer = true">
                    <i class="iconfont icon-liebiao"></i>
                </div>
                <!-- 桌面端 -->
                <div class="flex items-center">
                    <div class="hidden xl:block">
                        <el-input
                            class="pc-search"
                            v-model="searchValue"
                            :style="{
                                '--input-left-size': inputFocus ? '8px' : 0,
                                width: inputFocus ? '200px' : '40px'
                            }"
                            :placeholder="$t('inputPlaceholder')"
                            @keyup.enter="searchChange"
                            @focus="inputFocus = true"
                            @blur="inputFocus = false"
                        >
                            <template #prefix>
                                <i class="iconfont icon-sousuo"></i>
                            </template>
                        </el-input>
                    </div>
                    <Language class="ml-4 hidden xl:block" />
                </div>
            </div>
        </div>
        <!-- 移动端菜单 -->
        <el-drawer
            v-model="drawer"
            title=""
            direction="rtl"
            size="80%"
            :before-close="handleClose"
        >
            <div class="mobile-menu">
                <el-menu
                    class="w-full"
                    router
                    :default-active="defaultActive"
                    @select="handleSelect"
                >
                    <MenuItem :menuList="menuList"></MenuItem>
                </el-menu>
                <el-divider />
                <div class="flex items-center">
                    <span>{{ $t("currentLanguage") }}</span>
                    <Language class="ml-4" />
                </div>
                <el-input v-model="searchValue" class="w-full mt-4" :placeholder="$t('inputPlaceholder')" @keyup.enter="searchChange">
                    <template #append>
                        <el-button type="primary"><i class="iconfont icon-sousuo" @click="searchChange"></i></el-button>
                    </template>
                </el-input>
            </div>
        </el-drawer>
    </div>
    <ShowCookie />
</template>

<style>
.el-menu--horizontal .el-menu-item:focus, .el-menu--horizontal .el-menu-item:hover {
    color: var(--theme-hover-color) !important;
}
.el-menu--horizontal>.el-sub-menu .el-sub-menu__title:hover {
    color: var(--theme-hover-color) !important;
}
</style>

<style scoped lang="less">
.logo-title {
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
}

.menu {
    padding: 10px 0;
    border-bottom: 1px solid var(--el-menu-border-color);

    .el-menu-content {
        margin-bottom: -1px;

        :deep(.el-menu--horizontal>.el-menu-item) {
            margin: 0;
        }

        :deep(.el-sub-menu) {
            margin: 0;
        }

        :deep(.el-menu--horizontal>.el-sub-menu .el-sub-menu__title) {
            border-bottom: none !important;
        }

        :deep(.el-menu--horizontal>.el-menu-item) {
            border-bottom: none !important;
        }

        :deep(.el-menu) {
            border-bottom: none;
        }
    }

    :deep(.el-drawer__header) {
        margin-bottom: 0;
    }

    :deep(.el-drawer__body) {
        padding: 16px;
    }

    .mobile-menu {
        :deep(.el-menu) {
            border-right: 0;
        }
    }

    @media (min-width: 640px) {
        .el-menu-content {
            margin-bottom: -1px;

            :deep(.el-menu--horizontal>.el-menu-item) {
                margin: 0;
            }

            :deep(.el-sub-menu) {
                margin: 0;
            }
        }
    }

    @media (min-width: 768px) {
        .el-menu-content {
            margin-bottom: -1px;

            :deep(.el-menu--horizontal>.el-menu-item) {
                margin: 0;
            }

            :deep(.el-sub-menu) {
                margin: 0;
            }
        }
    }

    @media (min-width: 1024px) {
        .el-menu-content {
            margin-bottom: -1px;

            :deep(.el-menu--horizontal>.el-menu-item) {
                margin: 0;
            }

            :deep(.el-sub-menu) {
                margin: 0;
            }
        }
    }

    @media (min-width: 1280px) {
        .el-menu-content {
            margin-bottom: -1px;

            :deep(.el-menu--horizontal>.el-menu-item) {
                margin: 0 10px;
            }

            :deep(.el-sub-menu) {
                margin: 0 10px;
            }
        }
    }

    @media (min-width: 1536px) {
        .el-menu-content {
            margin-bottom: -1px;

            :deep(.el-menu--horizontal>.el-menu-item) {
                margin: 0 var(--max-margin-y);
            }

            :deep(.el-sub-menu) {
                margin: 0 var(--max-margin-y);
            }
        }
    }
}

.logo-info {
    display: flex;
    align-items: center;
    cursor: pointer;

    > img {
        width: 30px;
        margin-right: 6px;
    }

    > span {
        flex-shrink: 0;
        font-size: 28px;
        font-weight: 600;
        color: #333;
    }
}

.pc-search {
    transition: all .2s;

    .icon-sousuo {
        margin-right: 0;
    }

    :deep(.el-input__inner) {
        padding-left: var(--input-left-size);

        &::placeholder {
            padding-left: 8px;
        }
    }
}
</style>