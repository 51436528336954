const TokenKey = "token";

export function getToken() {
    return sessionStorage.getItem(TokenKey);
}

export function setToken(token) {
    return sessionStorage.setItem(TokenKey, token);
}

export function removeToken() {
    return sessionStorage.removeItem(TokenKey);
}

export function getItem(key) {
    return sessionStorage.getItem(key);
}

export function setItem(key, value) {
    return sessionStorage.setItem(key, value);
}

export function removeItem(key) {
    return sessionStorage.removeItem(key);
}

export function removeAll() {
    return sessionStorage.clear();
}

export function getLocalItem(key) {
    return localStorage.getItem(key);
}

export function setLocalItem(key, value) {
    return localStorage.setItem(key, value);
}