<script setup>
import {setItem} from "@/utils/auth.js";
import {nextTick, onMounted} from "vue";
import {useI18n} from "vue-i18n";
import {onBeforeRouteUpdate, useRoute} from "vue-router";

const {t, locale} = useI18n();
const route = useRoute();

const switchLanguage = (language) => {
    locale.value = language;
    setItem("localeLanguage", language);
    document.title = t(route.meta?.titleKey);
};

onBeforeRouteUpdate((to) => {
    document.title = t(to.meta?.titleKey);
});

onMounted(async () => {
    
});
</script>

<template>
    <div class="flex items-center justify-center flex-shrink-0">
        <span class="text-base text-slate-400 cursor-pointer font-semibold" :class="{active: locale === 'cn'}" @click="switchLanguage('cn')">中</span>
        <i class="iconfont icon-xieti text-slate-400"></i>
        <span class="text-base text-slate-400 cursor-pointer font-semibold" :class="{active: locale === 'en'}" @click="switchLanguage('en')">EN</span>
    </div>
</template>

<style scoped>
.active {
    color: var(--theme-title-color);
}
</style>