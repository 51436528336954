import {ref, computed} from "vue";
import {defineStore} from "pinia";

export const useMetaInfoStore = defineStore("metaInfo", () => {
    const metaInfo = ref(null);
    const i18nIsLoaded = ref(false); // i18n是否加载完毕, 默认否

    const setMetaInfo = (data) => {
        i18nIsLoaded.value = data;
    }

    return {
        metaInfo,
        i18nIsLoaded,
        setMetaInfo,
    };
});
