<script setup lang="jsx">
import {ref} from "vue";
import {onMounted, nextTick} from "vue";
import {RouterView, useRoute} from "vue-router";
import {handleLocalMessage} from "@/i18n/index.js";
import {request} from "@/utils/axiosSetting.js";
import {useMetaInfoStore} from "@/stores/meta_info.js";
import {useI18n} from "vue-i18n";

const {t, locale} = useI18n();
const route = useRoute();
const metaInfo = useMetaInfoStore();

const loading = ref(true);

const getData = async () => {
    metaInfo.setMetaInfo(false)
    const res = await request("/api/RestController.php", {
        method: "GET",
        params: {
            view: "web",
        },
    });

    let info = res.data;
    metaInfo.metaInfo = info;

    // document.title = info.title;
    document.querySelector("meta[name=\"keywords\"]").setAttribute("content", info.keyword);
    // document.querySelector("meta[name=\"description\"]").setAttribute("content", info.description);
    eval(info.siteCode);

    await nextTick();
    metaInfo.setMetaInfo(true)
    // 初始化标题
    if (route.meta?.titleKey) {
        document.title = t(route.meta?.titleKey);
    }
};

onMounted(async () => {
    try {
        loading.value = true;
        await handleLocalMessage();
        await getData();
    } finally {
        loading.value = false;
    }
});
</script>

<template>
    <div class="app-box" v-loading="loading" :style="{
        '--app-font-family': locale === 'en' ? 'Helvetica' : 'Microsoft YaHei',
    }">
        <RouterView />
    </div>
</template>

<style scoped lang="less">
.app-box {
    font-family: var(--app-font-family), serif;
}
</style>
